import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';

import GettingStarted from './Pages/GettingStarted/GettingStarted';
import Demo from './Pages/Demo/Demo';
import Layout from './Components/Layout/Layout';
import DemoDetails from './Pages/DemoDetails/DemoDetails';
import Vehicle from './Pages/Vehicle/Vehicle';
import VehicleVideos from './Pages/VideoPlaylist/VideoPlaylist';
import VehicleDetails from './Pages/VehicleDetails/VehicleDetails';
import FossPage from './Pages/FossPage/FossPage';
import PrivacyPage from './Pages/PrivacyPage/PrivacyPage';
import CookiesPage from './Pages/CookiesPage/CookiesPage';

function App() {

	// const VIMDT_BASEPATH = basePathFinder(window.location.hostname) || "https://vehicle-images.mercedes-benz.com"

	return (
		<Routes>
			<Route path="/video-playlist" element={<VehicleVideos />} />
			<Route path="" element={<Layout />}>
				<Route path="/home" element={<GettingStarted />} />
				<Route path="/demo" element={<Demo />} />
				<Route path="/vehicle/:detailsId" element={<VehicleDetails />} />
				<Route path="/demo/:vehicleId" element={<DemoDetails />} />
				<Route path="/vehicle" element={<Vehicle/>} />
				<Route path="/foss-disclosure" element={<FossPage />} />
				<Route path="/legal/privacy" element={<PrivacyPage />} />
				<Route path="/legal/cookies" element={<CookiesPage />} />
				<Route path="/" element={<Navigate to="/home" replace />} />
				<Route path="*" element={<Navigate to="/home" replace />} />
			</Route>
			<Route path="">
				<Route
					path="/download"
					element={<Redirect url={`${window.REACT_VIMDT_BASE_URL}/images`} />}
				/>
				<Route
					path="/images"
					element={<Redirect url={`${window.REACT_VIMDT_BASE_URL}/images`} />}
				/>
				<Route
					path="/equipments"
					element={<Redirect url={`${window.REACT_VIMDT_BASE_URL}/equipments`} />}
				/>
				<Route
					path="/videos"
					element={<Redirect url={`${window.REACT_VIMDT_BASE_URL}/videos`} />}
				/>
				<Route
					path="/specification"
					element={<Redirect url={`${window.REACT_VIMDT_BASE_URL}/specification`} />}
				/>
				<Route
					path="/models"
					element={<Redirect url={`${window.REACT_VIMDT_BASE_URL}/models`} />}
				/>
			</Route>
		</Routes>
	);
}

function Redirect(props) {
	const { url } = props;
	window.location.assign(url);
	return null;
}

// function basePathFinder(url) {

// 	const urls = {
// 		'retailer-dev.data.mercedes-benz.com': 'https://vehicle-images-dev.mercedes-benz.com',
// 		'retailer-stage.data.mercedes-benz.com': 'https://vehicle-images-stage.mercedes-benz.com',
// 		'retailer.data.mercedes-benz.com': 'https://vehicle-images.mercedes-benz.com'
// 	}

// 	return urls[url]
// }

export default App;
